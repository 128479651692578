.gpt4__features
    width: 100%
    display: flex
    justify-content: space-between 
    flex-direction: row

    &-heading
        flex: 1
        display: flex
        justify-content: flex-start
        align-items: flex-start
        flex-direction: column
        text-align: left
        margin-right: 5rem

        & h1
            font-family: var(--font-family)
            font-style: normal
            font-weight: 800
            font-size: 34px
            line-height: 45px

        & p
            font-family: var(--font-family)
            font-style: normal
            font-weight: 500
            font-size: 16px
            line-height: 30px
            color: #FF8A71
            margin-top: 2rem
            cursor: pointer

    &-container
        flex: 1.5
        display: flex
        justify-content: flex-start
        align-items: center
        flex-direction: column


@media screen and (max-width: 990px)
    .gpt4__features
        flex-direction: column
    
        &-heading
            margin: 0 0 2rem 0
    

@media screen and (max-width: 550px)
    .gpt4__features-heading h1
        font-size: 28px
        line-height: 38px
    