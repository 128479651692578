.gpt4__possibility
    display: flex
    flex-direction: row

    &-image
        flex: 1
        display: flex
        justify-content: flex-start
        align-items: center
        margin-right: 2rem

        & img
            width: 100%
            height: 100%

    &-content
        flex: 1
        display: flex
        flex-direction: column
        justify-content: flex-end
        align-items: flex-start
        margin-bottom: 2rem

        & h4
            font-family: var(--font-family)
            font-weight: 500
            font-size: 16px
            line-height: 30px
            color: #71E5FF
            cursor: pointer

        & h4:last-child
            color: #FF8A71

        & h1
            font-family: var(--font-family)
            font-weight: 800
            font-size: 34px
            line-height: 45px
            margin: 1rem 0

        & p
            font-family: var(--font-family)
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 24px
            color: var(--color-text)
            margin-bottom: 2rem


@media screen and (max-width: 990px)
    .gpt4__possibility
        flex-direction: column
        
        &-image
            margin: 1rem 0
        
        &-content
            margin-top: 2rem
        