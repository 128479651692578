.gpt4__navbar
    display: flex
    justify-content: space-between
    align-items: center
    padding: 2rem 6rem

    &-links
        flex: 1
        display: flex
        justify-content: flex-start
        align-items: center

        &_logo
            margin-right: 2rem

            & img
                width: 62.56px
                height: 62.56px

        &_container
            display: flex
            flex-direction: row

    &-sign
        display: flex
        align-content: flex-end
        align-items: center

    &-links_container p,
    &-sign p,
    &-menu_container p
        color: #fff
        font-family: var(--font-family)
        font-weight: 500
        font-size: 18px
        line-height: 25px
        text-transform: capitalize
        margin: 0 1rem
        cursor: pointer

    &-sign button,
    &-menu_container button 
        padding: 0.5rem 1rem
        color: #fff
        background: #FF4820
        font-family: var(--font-family)
        font-weight: 500
        font-size: 18px
        line-height: 25px
        border-radius: 5px
        border: 0
        outline: none
        cursor: pointer

    &-menu 
        margin-left: 1rem
        display: none
        position: relative

        & svg 
            cursor: pointer

        &_container 
            display: flex
            justify-content: flex-end
            align-items: flex-end
            flex-direction: column
            text-align: end
            background: var(--color-footer)
            padding: 2rem
            position: absolute
            top: 40px
            right: 0
            margin-top: 1rem
            min-width: 210px
            border-radius: 5px
            box-shadow: 0 0 5 rgba(0, 0, 0, 0.2)

    &_container 
        & p 
           margin: 1rem 0

        &-links-sign 
            display: none

@media screen and (max-width: 1050px) 
    .gpt4__navbar
        &-links_container 
            display: none
        
        &-menu 
            display: flex  

@media screen and (max-width: 700px) 
    .gpt4__navbar 
        padding: 2rem 4rem
    
@media screen and (max-width: 550px) 
    .gpt4__navbar 
        padding: 1rem
        
        &-sign 
            display: none
        
        &-menu_container 
            top: 20px
            
            &-links-sign 
                display: block
            
        &-links_logo img
            margin-left: 1rem
