.gpt4__cta

    display: flex
    justify-content: space-between
    align-items: center
    flex-direction: row
    padding: 2rem
    margin: 4rem
    border-radius: 1rem
    background: var(--gradient-bar)

    &-content 
        & p
            font-family: var(--font-family)
            font-weight: 500
            font-size: 12px
            line-height: 30px
            color: #0E0E0E

        & h3
            font-family: var(--font-family)
            font-weight: 800
            font-size: 24px
            line-height: 34px


    &-btn
        display: flex
        justify-content: center
        align-items: center
        margin-left: 2rem

        & button
            background: #000000
            color: #FFFFFF
            border-radius: 40px
            font-family: var(--font-family)
            font-weight: bold
            font-size: 18px
            line-height: 30px
            border: none
            outline: none
            cursor: pointer
            min-width: 150px
            min-height: 45px


@media screen and (max-width: 650px) 
    .gpt4__cta
        flex-direction: column
    
        &-btn
            margin: 2rem 0 0
    


@media screen and (max-width: 550px) 
    .gpt4__cta
        margin: 2rem
    
        &-content h3
            font-size: 18px
            line-height: 32px
        
        &-btn button
            font-size: 14px
            line-height: 28px
    
