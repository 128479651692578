.gpt4__blog
    display: flex
    flex-direction: column

    &-heading
        width: 100%
        text-align: left

        margin-bottom: 5rem

    &-heading h1
        font-family: var(--font-family)
        font-weight: 800
        font-size: 62px
        line-height: 75px

        letter-spacing: -0.04em

.gpt4__blog-container 
    display: flex
    flex-direction: row

    &_groupA
        flex: 0.75
        margin-right: 2rem

    &_groupB
        flex: 1
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 2rem


@media screen and (max-width:990px)
    .gpt4__blog-container 
        flex-direction: column-reverse
        &_groupA
            margin: 2rem 0
    
        &_groupA .gpt4__blog-container_article
            width: 48%


@media screen and (max-width:700px)
    .gpt4__blog-container_groupB
        grid-template-columns: repeat(1, 1fr)

    .gpt4__blog-container_groupA .gpt4__blog-container_article
        width: 100%

    .gpt4__blog-heading h1
        font-size: 46px
        line-height: 52px
        

@media screen and (max-width:550px)
    .gpt4__blog-heading h1
        font-size: 34px
        line-height: 42px