.gpt4__header
    display: flex

    &-content
        flex: 1
        display: flex
        justify-content: center
        align-items: flex-start
        flex-direction: column
        margin-left: 5rem
        margin-right: 5rem

        & h1
            font-family: var(--font-family)
            font-weight: 800
            font-size: 62px
            line-height: 75px
            letter-spacing: -0.04em

        & p
            font-family: var(--font-family)
            font-size: 20px
            line-height: 27px
            color: var(--color-text)
            margin-top: 1.5rem

        &__input
            width: 100%
            margin: 2rem 0 1rem
            display: flex

            & input
                flex: 2
                width: 100%
                min-height: 50px
                font-family: var(--font-family)
                font-size: 20px
                line-height: 27px
                background: var(--color-footer)
                border: 2px solid var(--color-footer)
                padding: 0 1rem
                outline: none
                color: #fff
                border-top-left-radius: 5px
                border-bottom-left-radius: 5px

            & button
                flex: 0.6
                width: 100%
                min-height: 50px
                font-family: var(--font-family)
                font-weight: bold
                font-size: 20px
                line-height: 27px
                color: #FFFFFF
                background: #FF4820
                cursor: pointer
                outline: none
                padding: 0 1rem
                border: none
                border-top-right-radius: 5px
                border-bottom-right-radius: 5px

        &__people
            width: 100%
            display: flex
            justify-content: flex-start
            align-items: center
            margin-top: 2rem

            & img
                width: 181.79px
                height: 40px

            & p
                font-family: var(--font-family)
                font-weight: 500
                font-size: 12px
                line-height: 38px
                color: #FFFFFF
                text-align: center
                margin: 0 0 0 1rem

    &-image 
        display: flex
        flex: 1
        justify-content: center
        align-items: center

        & img
            width: 100%
            height: 100%


@media screen and (max-width: 1050px)
    .gpt4__header 
        flex-direction: column
    
        &-content 
            margin: 0 3rem 3rem
    
@media screen and (max-width: 650px)
    .gpt4__header
        & h1 
            font-size: 48px
            line-height: 60px
        
        & p 
            font-size: 16px
            line-height: 24px
        
        &-content
            &__people 
                flex-direction: column

                & p 
                    margin: 0

            &__input 
                & input,& button 
                    font-size: 16px
                    line-height: 24px
    
@media screen and (max-width: 490px) 
    .gpt4__header
        & h1 
            font-size: 36px
            line-height: 48px
        
        & p 
            font-size: 14px
            line-height: 24px
        
        &-content__input 
            & input,& button 
                font-size: 12px
                line-height: 16px