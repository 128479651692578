@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap')

:root
  --color-bg: #040C18
  --color-blog: #042c54
  --color-footer: #031B34
  --color-subtext: #FF8A71
  --color-text: #81AFDD
  --font-family: 'Manrope', sans-serif
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%)
  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%)