.gpt4__whatgpt4 
    display: flex
    flex-direction: column

    padding: 2rem
    margin: 4rem
    background: var(--color-footer)
    //!TODO solve <w> errors in sass
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%)

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%)

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%)

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%)

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%)

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))


    &-feature 
        display: flex
        margin: 1rem

        & .gpt4__features-container__feature 
            margin: 0

        & .gpt4__features-container_feature-text 
            max-width: 700px

    &-heading 
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        
        margin: 4rem 0 2rem

        & h1 
            font-size: 34px
            line-height: 45px
            font-weight: 800
            font-family: var(--font-family)
            max-width: 510px

        & p 
            font-family: var(--font-family)
            font-weight: 500
            font-size: 16px
            line-height: 30px
            color: var(--color-subtext)
            cursor: pointer

    &-container 
        display: flex
        flex-wrap: wrap
        flex-direction: row
        margin-top: 2rem

        & .gpt4__features-container__feature 
            flex: 1
            margin: 1rem
            min-width: 210px
            display: unset
            flex-direction: column

        & .gpt4__features-container_feature-text 
            margin-top: 0.5rem


@media screen and (max-width: 850px) 
    .gpt4__whatgpt4-heading 
        flex-direction: column
        align-items: flex-start
        margin-bottom: 0rem
        
        & p
            margin-top: 1rem
    
@media screen and (max-width: 650px) 
    .gpt4__whatgpt4
        margin: 2rem
    
        &-feature .gpt4__features-container__feature 
            flex-direction: column
    
            & .gpt4__features-container_feature-text 
                margin-top: 0.5rem
            
        &-heading h1 
            font-size: 28px
            line-height: 40px
    
@media screen and (max-width: 350px) 
    .gpt4__whatgpt4-container .gpt4__features-container__feature 
        margin: 1rem 0
        min-width: 100%
    