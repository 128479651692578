.gpt4__footer
    color: white
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background: var(--color-footer)

    &-heading
        width: 100%
        text-align: center
        margin-bottom: 3rem

        & h1
            font-family: var(--font-family)
            font-weight: 800
            font-size: 62px
            line-height: 75px
            text-align: center
            letter-spacing: -0.04em

    &-btn
        display: flex
        justify-content: center
        align-items: center
        padding: 1rem
        border: 1px solid #FFFFFF
        text-align: center
        margin-bottom: 10rem
        cursor: pointer

        & p
            box-sizing: border-box
            font-family: var(--font-family)
            font-size: 18px
            line-height: 22px
            color: #FFFFFF
            word-spacing: 2px


    &-links
        display: flex
        justify-content: space-between
        align-items: flex-start
        flex-wrap: wrap
        flex-direction: row

        & div
            width: 250px
            margin: 1rem

        &_logo 
            & img
                width: 118px
                height: 118px

            & p
                font-family: var(--font-family)
                font-size: 12px
                font-weight: 14px
                color: #FFFFFF

        &_div
            font-family: var(--font-family)
            font-size: 14px
            line-height: 16px
            color: #FFFFFF
            margin-bottom: 1rem

            & h4
                font-family: var(--font-family)
                font-size: 18px
                line-height: 20px
                color: #FFFFFF
                margin-bottom: 2rem

            & p
                font-family: var(--font-family)
                font-size: 12px
                line-height: 14px
                color: #FFFFFF
                margin-top: 1rem
                cursor: pointer

    &-copyright p
        font-family: var(--font-family)
        font-size: 12px
        line-height: 50px


@media screen and (max-width: 850px) 
    .gpt4__footer-heading h1
        font-size: 44px
        line-height: 50px
    

@media screen and (max-width: 550px) 
    .gpt4__footer
        &-heading h1
            font-size: 34px
            line-height: 42px
        
        &-links div
            margin: 1rem 0
        
        &-btn p
            font-size: 14px
            line-height: 20px
        